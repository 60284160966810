import { useMemo } from "react";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { ADMIN_MEMO } from "src/api/public-types";
import { ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { useQueryParams } from "src/hooks/useQueryParams";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import { useParams } from "react-router-dom";

/* 
  공지사항 > 상세
 */
const tabs = [{ value: "basicInfo", label: "기본정보" }];

const GuideDetail = () => {
  const { queryParams } = useQueryParams();
  const { id } = useParams();

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  return (
    <div className="">
      <ContentsTitle
        title="이용안내"
        tabs={tabs}
        activeTab={activeTab}
        detailInfo={[{ title: "id", value: queryParams.id ? String(queryParams.id) : "" }]}
      />
      {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
    </div>
  );
};
export default GuideDetail;
