import { useMemo, useState } from "react";

import { BaseModal, ContentsTitle } from "src/components";

import { BasicInfoForm, CommonSpace, ProductInfoForm } from "./forms";

import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import MetaTag from "src/components/layout/MetaTag";

import useGetProduct from "src/hooks/product/useGetProduct";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useQueryParams } from "src/hooks/useQueryParams";
import GuideTab from "../guide/components/GuideTab";
import { ProductAccessGroup } from "./forms/accessLevelForm";
import ChargeForm from "./forms/chargeManagement/ChargeFrom";
import ImageForm from "./forms/imageForm/ImageForm";
import ProductIotDevice from "./forms/iotDevice/ProductIotDevice";
import { ProductTypes, tabs } from "./product-types";

/* 
  공간상품 > 등록 or 수정
 */
const ProductForm = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { queryParams } = useQueryParams();
  usePartnerAuthority({ checkPageAccess: true });

  const productId: string | undefined = queryParams?.id ? String(queryParams?.id) : undefined;

  // 공간상품 상세 get Api
  const { data: product } = useGetProduct({ productId: productId });

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0], [queryParams]);

  /** V타입에서 공용공간 탭을 제거하기 위한 필터
   *  S2타입에서 요금관리 탭 제거
   */
  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => {
      if (product?.productType === ProductTypes.NON_RESIDENT_OFFICE && tab.value === "commonSpace") {
        return false;
      }
      if (product?.productType === ProductTypes.TIME_COURT && tab.value === "charge") {
        return false;
      }
      return true;
    });
  }, [product]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={product?.productName} isCreatePage={productId === undefined} />
      {modalOpen && (
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)} title="기본정보가 저장되지 않았습니다"></BaseModal>
      )}
      <ContentsTitle title="공간상품" tabs={filteredTabs} activeTab={activeTab} detailInfo={[{ title: "id", value: productId ?? "" }]} />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab.value === "basicInfo" && <BasicInfoForm product={product} productId={productId} />}
      {/* 전시정보 */}
      {activeTab.value === "productInfo" && product && productId && <ProductInfoForm product={product!} productId={productId} />}
      {/* 이미지 */}
      {activeTab.value === "image" && <ImageForm />}
      {/* 요금관리 */}
      {activeTab.value === "charge" && product && productId && <ChargeForm product={product!} productId={productId} />}
      {/* 공용공간 */}
      {activeTab?.value === "commonSpace" && product && productId && <CommonSpace product={product} productId={productId} />}
      {/* 출입그룹 */}
      {activeTab.value === "accessLevel" && <ProductAccessGroup product={product!} />}

      {/* 이용안내 */}
      {activeTab?.value === "guide" && <GuideTab serviceId={String(product?.id)} serviceType={"SERVICE_PRODUCT"} product={product!} />}
      {/* IoT */}
      {activeTab?.value === "iotDevice" && <ProductIotDevice product={product!} />}
    </div>
  );
};
export default ProductForm;
