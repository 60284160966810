import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, Sort } from "../public-types";
import qs from "qs";
import { ApiPath } from "..";
import { GuideListParams, GuideMappingListParams, ServiceUserGuide, UserGuideModel } from "./guide-types";

/* 
  REU01. 이용안내 목록조회
*/
export async function getGuideListAsync(
  axios: AxiosInstance,
  params?: GuideListParams,
): Promise<AxiosResponse<ApiResponse<{ content: UserGuideModel[] }>>> {
  return await axios.get(ApiPath.guide.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  REU02. 이용안내 등록
  https://pnpt.atlassian.net/wiki/x/dQBLGg
*/
export async function postGuideAsync(
  axios: AxiosInstance,
  params?: UserGuideModel,
): Promise<AxiosResponse<ApiResponse<{ content: UserGuideModel }>>> {
  return await axios.post(ApiPath.guide.post, {
    userGuide: params,
  });
}

/* 
  REU03. 이용안내 수정
  https://pnpt.atlassian.net/wiki/x/iABLGg
*/
export async function editGuideAsync(
  axios: AxiosInstance,
  params?: UserGuideModel,
): Promise<AxiosResponse<ApiResponse<{ content: UserGuideModel }>>> {
  if (!params?.id) {
    throw Error("id is not found");
  }
  return await axios.put(ApiPath.guide.edit.replace("{id}", params.id.toString()), {
    userGuide: params,
  });
}

/* 
  REU02. 이용안내 삭제
  https://pnpt.atlassian.net/wiki/x/dQBLGg
*/
export async function deleteGuideAsync(
  axios: AxiosInstance,
  params?: {
    idList: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: string }>>> {
  // 삭제된 카운트 응답
  return await axios.delete(ApiPath.guide.delete, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/////////////////////////////

/* 
REU05. 서비스-이용안내 목록
https://pnpt.atlassian.net/wiki/x/UgFJGg
*/
export async function getGuideMappingListAsync(
  axios: AxiosInstance,
  params?: GuideMappingListParams,
): Promise<AxiosResponse<ApiResponse<{ content: ServiceUserGuide[] }>>> {
  return await axios.get(ApiPath.guide.mapping, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  REU06. 서비스-이용안내 등록/수정/삭제
  https://pnpt.atlassian.net/wiki/x/ZgFJGg

*/
export async function postGuideMappingAsync(
  axios: AxiosInstance,
  params?: ServiceUserGuide[],
): Promise<AxiosResponse<ApiResponse<{ content: ServiceUserGuide[] }>>> {
  return await axios.post(ApiPath.guide.mapping, {
    serviceUserGuideList: params,
  });
}
