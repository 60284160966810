import BuildingDetail from "src/pages/building/details/BuildingDetail";
import BuildingList from "src/pages/building/list/BuildingList";
import InitCtrlRoom from "src/pages/init/InitCtrlRoom";
import Login from "src/pages/login/Login";
import Main from "src/pages/Main";
import RootPage from "src/pages/root/RootPage";
import WorkOrderDetailAuthenticated from "src/pages/workOrder/workOrderAuthenticated/detail/WorkOrderDetailAuthenticated";
import WorkOrderListAuthenticated from "src/pages/workOrder/workOrderAuthenticated/list/WorkOrderListAuthenticated";

import WorkOrderDetail from "src/pages/workOrder/WorkOrderDetail";
import WorkOrderList from "src/pages/workOrder/WorkOrderList";

import ContractDetailMain from "src/pages/contract/detail/ContractDetailMain";
import ContractFormMain from "src/pages/contract/form/ContractFormMain";
import ContractList from "src/pages/contract/list/ContractList";

import BillingScheduleDetail from "src/pages/billing/detail/BillingScheduleDetail";
import BillingScheduleForm from "src/pages/billing/form/BillingScheduleDetail";
import BillingList from "src/pages/billing/list/BillingScheduleList";

import ReservationDetail from "src/pages/reservation/detail/ReservationDetail";
import ReservationList from "src/pages/reservation/list/ReservationList";

import OrganizaionDetail from "src/pages/organization/detail/OrganizationDetail";
import OrganizaionForm from "src/pages/organization/form/OrganizationForm";
import OrganizaionList from "src/pages/organization/list/OrganizationList";

import ChargeItemsDetail from "src/pages/chargeItems/detail/ChargeItems";
import ChargeItemsForm from "src/pages/chargeItems/form/ChargeItemsForm";
import ChargeItemsList from "src/pages/chargeItems/list/ChargeItemsList";
import ChargeMntDetail from "src/pages/chargeMnt/detail/ChargeMntDetail";
import ChargeMntForm from "src/pages/chargeMnt/form/ChargeMntForm";
import ChargeMntList from "src/pages/chargeMnt/list/ChargeMntList";

import AccessDenied from "src/pages/accessDenied/AccessDenied";
import Dashboard from "src/pages/dashboard";
import WorkOrderFormAuthenticated from "src/pages/workOrder/workOrderAuthenticated/form/WorkOrderFormAuthenticated";
import { Route } from "src/types/common";
import PagePath from "../pagePath.json";

import { PartnerFeature } from "src/api/public-types";
import BuildingForm from "src/pages/building/forms/BuildingForm";
import NoticeDetail from "src/pages/notice/detail/NoticeDetail";
import NoticeForm from "src/pages/notice/form/NoticeForm";
import NoticeList from "src/pages/notice/list/NoticeList";
import PriceDetail from "src/pages/price/detail/PriceDetail";
import PriceForm from "src/pages/price/form/PriceForm";
import PriceList from "src/pages/price/list/PriceList";
import { ProductDetail, ProductForm, ProductList } from "src/pages/product";
import ProviderDetail from "src/pages/provider/detail/ProviderDetail";
import ProviderForm from "src/pages/provider/forms/ProviderForm";
import ProviderList from "src/pages/provider/list/ProviderList";
import QuestionDetail from "src/pages/question/detail/QuestionDetail";
import QuestionForm from "src/pages/question/form/QuestionForm";
import QuestionList from "src/pages/question/list/QuestionList";
import { UserDetail, UserForm, UserList } from "src/pages/user";
import { VisitorAccessInfo, VisitorDetail, VisitorList } from "src/pages/visitor";
import VisitorAdd from "src/pages/visitor/add/VisitorAdd";
import VisitorEdit from "src/pages/visitor/edit/VisitorEdit";
import VocDetail from "src/pages/voc/detail/VocDetail";
import VocForm from "src/pages/voc/form/VocForm";
import VocList from "src/pages/voc/list/VocList";
import ClosedPeriodList from "src/pages/closedPeriod/list/ClosedPeriodList";
import ClosedPeriodForm from "src/pages/closedPeriod/form/ClosedPeriodForm";
import ClosedPeriodDetail from "src/pages/closedPeriod/detail/ClosedPeriodDetail";
import SpaceMonitoring from "src/pages/monitoring/SpaceMonitoring";

import GuideList from "src/pages/guide/list/GuideList";
import GuideForm from "src/pages/guide/form/GuideForm";
import GuideDetail from "src/pages/guide/detail/GuideDetail";

export const routeList: Route[] = [
  { path: `/`, component: RootPage },

  // 관리자 권한 없는 페이지
  { path: PagePath.accessDenied, component: AccessDenied, isProtectRoute: true },

  // 대시보드
  {
    path: PagePath.main,
    component: Dashboard,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },

  // 공간 모니터링
  {
    path: PagePath.monitoring,
    component: SpaceMonitoring,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },

  // 워크오더 무인증
  { path: PagePath.workOrder.nonAuthenticated.detail, component: WorkOrderDetail },
  { path: PagePath.workOrder.nonAuthenticated.list, component: WorkOrderList },

  //워크오더 인증
  {
    path: PagePath.workOrder.authenticated.list,
    component: WorkOrderListAuthenticated,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.WORKORDER,
    // privilege: PartnerPrivilegeCode.WORKORDER,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.workOrder.authenticated.detail,
    component: WorkOrderDetailAuthenticated,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.WORKORDER,
    // privilege: PartnerPrivilegeCode.WORKORDER,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.workOrder.authenticated.form,
    component: WorkOrderFormAuthenticated,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.WORKORDER,
    // privilege: PartnerPrivilegeCode.WORKORDER,
    // permission: PartnerPermission.WRITE,
  },

  // 로그인
  { path: PagePath.login, component: Login },
  { path: PagePath.partnerLogin, component: Login },
  { path: PagePath.init, component: InitCtrlRoom, isProtectRoute: true },
  // { path: PagePath.main, component: Main, isProtectRoute: true, withPartner: true },

  // 건물
  {
    path: PagePath.building.list,
    component: BuildingList,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.BUILDING,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.building.detail,
    component: BuildingDetail,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.BUILDING,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.building.form,
    component: BuildingForm,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.BUILDING,
    // permission: PartnerPermission.WRITE,
  },

  //신청/계약
  {
    path: PagePath.contract.list,
    component: ContractList,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.contract.detail,
    component: ContractDetailMain,
    withPartner: true,
    isProtectRoute: true,
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.contract.form,
    component: ContractFormMain,
    withPartner: true,
    isProtectRoute: true,
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.WRITE,
  },
  //빌링스케줄
  {
    path: PagePath.billing.list,
    component: BillingList,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.billing.detail,
    component: BillingScheduleDetail,
    withPartner: true,
    isProtectRoute: true,
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.billing.form,
    component: BillingScheduleForm,
    withPartner: true,
    isProtectRoute: true, //
    // privilege: PartnerPrivilegeCode.CONTRACT,
    // permission: PartnerPermission.WRITE,
  },

  // 공용공간 예약
  {
    path: PagePath.reservation.list, //
    component: ReservationList,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // privilege: PartnerPrivilegeCode.RESERVATION,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.reservation.detail, //
    component: ReservationDetail,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // privilege: PartnerPrivilegeCode.RESERVATION,
    // permission: PartnerPermission.READ,
  },

  // 공용공간 요금
  {
    path: PagePath.price.list, //
    component: PriceList,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.price.form, //
    component: PriceForm,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.price.detail, //
    component: PriceDetail,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },

  // 공용공간 휴무일시
  {
    path: PagePath.closedPeriod.list, //
    component: ClosedPeriodList,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.closedPeriod.form, //
    component: ClosedPeriodForm,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.closedPeriod.detail, //
    component: ClosedPeriodDetail,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.RESERVATION,
    // permission: PartnerPermission.READ,
  },

  // 사업자
  {
    path: PagePath.organization.list,
    component: OrganizaionList,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.organization.form,
    component: OrganizaionForm,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.organization.detail,
    component: OrganizaionDetail,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },

  // 이용자
  {
    path: PagePath.user.list,
    component: UserList,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.CONTRACT, //
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.user.form,
    component: UserForm,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.CONTRACT, //
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.user.detail,
    component: UserDetail,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.CONTRACT, //
    // permission: PartnerPermission.READ,
  },

  // 방문자
  {
    path: PagePath.visitor.list,
    component: VisitorList,
    isProtectRoute: true,
    withPartner: true,
    //privilege: PartnerPrivilegeCode.VISITOR
  },
  {
    path: PagePath.visitor.add,
    component: VisitorAdd,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.VISITOR,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.visitor.edit,
    component: VisitorEdit,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.VISITOR,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.visitor.detail,
    component: VisitorDetail,
    isProtectRoute: true,
    withPartner: true,
    //  privilege: PartnerPrivilegeCode.VISITOR
  },

  {
    path: PagePath.visitor.accessInfo,
    component: VisitorAccessInfo,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.VISITOR,
    // permission: PartnerPermission.READ,
  },

  // 공간상품
  {
    path: PagePath.product.list,
    component: ProductList,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.PRODUCT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.product.detail,
    component: ProductDetail,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.PRODUCT,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.product.form,
    component: ProductForm,
    isProtectRoute: true,
    withPartner: true, //
    // privilege: PartnerPrivilegeCode.PRODUCT,
    // permission: PartnerPermission.WRITE,
  },

  // 정산정보
  {
    path: PagePath.provider.list,
    component: ProviderList,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC, //
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.provider.form,
    component: ProviderForm,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.provider.detail,
    component: ProviderDetail,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC, //
    // permission: PartnerPermission.READ,
  },
  // 관리비 부과항목관리
  {
    path: PagePath.chargeItems.form,
    component: ChargeItemsForm,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.chargeItems.list,
    component: ChargeItemsList,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.chargeItems.detail,
    component: ChargeItemsDetail,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.READ,
  },

  // 관리비 부과항목관리
  {
    path: PagePath.chargeMnt.list,
    component: ChargeMntList,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.chargeMnt.form,
    component: ChargeMntForm,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.chargeMnt.detail,
    component: ChargeMntDetail,
    isProtectRoute: true,
    withPartner: true,
    allowEnvironments: ["dev", "stg"],
    feature: PartnerFeature.MANAGEMENT_FEE,
    // permission: PartnerPermission.READ,
  },

  // 공지사항
  {
    path: PagePath.notice.list,
    component: NoticeList,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC, //
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.notice.detail,
    component: NoticeDetail,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC, //
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.notice.form,
    component: NoticeForm,
    isProtectRoute: true,
    withPartner: true,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },

  // 상담관리
  {
    path: PagePath.question.list,
    component: QuestionList,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.CS,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.question.detail,
    component: QuestionDetail,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.CS,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.question.form,
    component: QuestionForm,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.CS,
    // permission: PartnerPermission.WRITE,
  },

  // voc
  {
    path: PagePath.voc.list,
    component: VocList,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.voc.detail,
    component: VocDetail,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.READ,
  },
  {
    path: PagePath.voc.form,
    component: VocForm,
    isProtectRoute: true,
    withPartner: true,
    feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.guide.list,
    component: GuideList,
    isProtectRoute: true,
    withPartner: true,
    // feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.guide.form,
    component: GuideForm,
    isProtectRoute: true,
    withPartner: true,
    // feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
  {
    path: PagePath.guide.detail,
    component: GuideDetail,
    isProtectRoute: true,
    withPartner: true,
    // feature: PartnerFeature.CS,
    // privilege: PartnerPrivilegeCode.BASIC,
    // permission: PartnerPermission.WRITE,
  },
];
