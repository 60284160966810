import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CellValue, Row } from "react-table";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { deleteOperationFacilityAsync } from "src/api/contract/contract-facility-api";
import { DetailTotalData } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import useContractFacilityApi from "src/pages/contract/hooks/useContractFacilityApi";
import { PagePath } from "src/pages/product/details";
import Price from "src/pages/product/forms/commonSpaceForm/modal/Price";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { ContractFacilityTableData } from "../components/ContractSpaceContents";
import SettingModal from "../modal/SettingModal";
import BaseNewTabLink from "src/components/BaseNewTabLink";

interface CellProps<V = any> {
  value: CellValue<V>;
  space: CommonFacilityListModel;
  row: Row<ContractFacilityTableData>;
  detailData: DetailTotalData;
  isEditable: boolean;
}

export const meeting_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 72,
  },
  {
    Header: "회의실 명",
    accessor: "facilityName",
    width: 128,
    Cell: ({ row, value }: CellProps) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "정원",
    accessor: "facilityMaxPeopleNums",
    width: 70,
    Cell: ({ row, value }: CellProps) => {
      return <p>{value ? value + "인" : "-"}</p>;
    },
  },
  {
    Header: "위치",
    accessor: "location",
    width: 96,
    Cell: ({ row, value }: CellProps) => {
      const isGround = row.original.isGround;
      const floorNum = row.original.floorNum;
      return (
        <p>
          {isGround ? "지상" : "지하"} {floorNum}층
        </p>
      );
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 148,
    Cell: ({ value, row, detailData }: any) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];

export const refresh_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 72,
  },
  {
    Header: "편의시설 명",
    accessor: "facilityName",
    width: 198,
    Cell: ({ row, value }: CellProps) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 80,
    Cell: ({ row, value }: CellProps) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 140,
    Cell: ({ value, row, detailData }: CellProps) => {
      const path = PagePath.building.detail.replace(":id", String(row?.original?.buildingId));
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];

export const desk_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "좌석그룹 명",
    accessor: "buildingCommonFacilityDeskGroupName",
    width: 120,
    Cell: ({ row, value }: CellProps) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "좌석 명",
    accessor: "facilityName",
    width: 80,
    Cell: ({ row, value }: CellProps) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 80,
    Cell: ({ row, value }: CellProps) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value}층
        </p>
      );
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 130,
    Cell: ({ value, row, detailData }: CellProps) => {
      const path = PagePath.building.detail.replace(":id", String(row.original.buildingId));
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];
export const price_cols: any[] = [
  {
    Header: "30분당 요금",
    accessor: "priceForThirty",
    className: "required",
    width: 100,
    Cell: ({ row, detailData }: CellProps) => {
      const { setAbstractModalZ1 } = useModal();

      return (
        <div className="w-100 text-left">
          <BaseButton
            title={"요금 확인"}
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "xlarge",
                children: (
                  <Price
                    space={row.original as any}
                    isEditable={false}
                    productId={detailData?.product?.id}
                    serviceId={String(detailData.contract?.contractId)}
                    serviceType="SERVICE_CONTRACT"
                  />
                ),
              })
            }
          />
        </div>
      );
    },
  },
];
export const duplicate_cols: any[] = [
  {
    Header: () => (
      <div className="no-wrap">
        <p>무제한 여부</p>
      </div>
    ),
    accessor: "isUnlimitedReservation",
    className: "required",
    width: 72,
    Cell: ({ row }: CellProps) => {
      const [priceType, setPriceType] = useState(false);
      useEffect(() => {
        setPriceType(row.original.isUnlimitedReservation!);
      }, [row.original]);
      return (
        <>
          <div className="w-100 text-center">
            <p>{priceType ? "무제한" : "시간제"}</p>
          </div>
        </>
      );
    },
  },

  {
    Header: "월 이용한도",
    accessor: "monthlyServiceMinutes",
    className: "required",
    width: 108,
    Cell: ({ row }: CellProps) => {
      const [monthyTime, setMonthyTime] = useState<string | number | undefined>("");
      useEffect(() => {
        setMonthyTime(row.original.monthlyServiceMinutes);
      }, [row.original]);
      return (
        <>
          <div className="w-100 text-center minmax250">
            <p>
              {row.original.isUnlimitedReservation && "-"}
              {!row.original.isUnlimitedReservation && Number(monthyTime) <= 0 && "-"}
              {!row.original.isUnlimitedReservation && String(monthyTime) !== "-1" && Math.floor(Number(monthyTime) / 60) > 0
                ? Math.floor(Number(monthyTime) / 60) + "시간 "
                : ""}
              {!row.original.isUnlimitedReservation && Number(monthyTime) % 60 > 0 ? (Number(monthyTime) % 60) + "분" : ""}
            </p>
          </div>
        </>
      );
    },
  },
  {
    Header: "일 이용한도",
    accessor: "dailyServiceMinutes",
    width: 80,
    Cell: ({ row }: CellProps) => {
      const [dayTime, setDayTime] = useState<string | number | undefined>("");

      useEffect(() => {
        setDayTime(row.original.dailyServiceMinutes);
      }, [row.original]);
      return (
        <>
          <div className="w-100 text-center minmax250">
            <p>
              {row.original.isUnlimitedReservation && "-"}
              {!row.original.isUnlimitedReservation && Number(dayTime) <= 0 && "-"}
              {!row.original.isUnlimitedReservation && String(dayTime) !== "-1" && Math.floor(Number(dayTime) / 60) > 0
                ? Math.floor(Number(dayTime) / 60) + "시간 "
                : ""}
              {!row.original.isUnlimitedReservation && Number(dayTime) % 60 > 0 ? (Number(dayTime) % 60) + "분" : ""}
            </p>
          </div>
        </>
      );
    },
  },
];
export const overCharge_cols: any[] = [
  {
    Header: () => (
      <div className="no-wrap">
        <p>초과 예약</p>
      </div>
    ),
    accessor: "isAllowedOverReservation",
    className: "required",
    width: 64,
    Cell: ({ row }: CellProps) => {
      const [overReservation, setOverReservation] = useState<boolean>();
      useEffect(() => {
        setOverReservation(row.original.isAllowedOverReservation);
      }, [row.original]);
      return (
        <>
          <div className="w-100 text-center">
            <p>{row.original.isUnlimitedReservation ? "-" : String(overReservation) === "true" ? "허용" : "비허용"}</p>
          </div>
        </>
      );
    },
  },
];

export const edit_cols: any[] = [
  {
    Header: "설정",
    accessor: "edit",
    width: 80,
    Cell: ({ row, detailData }: CellProps) => {
      const { setContractPriceRules, insertOrUpdate } = useContractFacilityApi();

      const { setAbstractModalZ1, abstractModalZ1 } = useModal();

      return (
        <div className="flex-row flex-center">
          <BaseButton
            title={"설정"}
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "xlarge",
                className: "overflow-x-hidden",
                children: <SettingModal space={row.original} isEditable={true} detailData={detailData} />,
              })
            }
          />
        </div>
      );
    },
  },
];
// edit_cols 에 있던 삭제 부분 분리
export const delete_cols: any[] = [
  {
    Header: "삭제",
    accessor: "btn",
    width: 48,
    Cell: ({ row, detailData }: { row: any; detailData: DetailTotalData }) => {
      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      const { openToast } = useToast();
      const { executeAsync: deleteOperationFacility } = useApiOperation(deleteOperationFacilityAsync);

      const { setBaseModal } = useModal();
      const queryClient = useQueryClient();
      return (
        <button
          className="base-trash-btn color-bg-gray"
          disabled={
            detailData?.contract?.contractStep === "APPLY_CANCEL" ||
            detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
            detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
            detailData?.contract?.contractStep === "USE_COMPLETE"
          }
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnLeftTitle: "취소",
              btnRightTitle: "확인",
              title: `해당 ${SpaceTypeLabel[spaceType]}을 삭제합니다`,
              children: `삭제한 즉시 해당 ${SpaceTypeLabel[spaceType]}에 엑세스 할 수 없습니다`,
              onClick: () => {
                deleteOperationFacility({ contractId: detailData.contract?.contractId!, facilityId: row.original.id })
                  .then((res) => res.status === 200 && openToast({ content: `정상적으로 삭제 되었습니다.` }))
                  .finally(() => {
                    setBaseModal({ isOpen: false });
                    queryClient.invalidateQueries();
                  });
              },
            });
          }}
        ></button>
      );
    },
  },
];
