import { Dispatch, SetStateAction } from "react";

import { useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { ServiceUserGuide, UserGuideType, UserGuideTypeMap } from "src/api/guide/guide-types";
import { BaseButton, BaseInput } from "src/components";
import usePostGuideMapping from "src/hooks/guide/usePostGuideMapping";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import GuideBaseTable from "./GuideBaseTable";

import produce from "immer";
import { CellValue, Row } from "react-table";
import { PagePath } from "src/pages/product/details";

import BaseNewTabLink from "src/components/BaseNewTabLink";
import { compareCUDData, hasDuplicateValue, onlyNumber } from "src/utils";

interface CellProps<R extends object = any, V = any> {
  value: CellValue<V>;
  row: Row<R>;
  setServiceUserGuideListState: Dispatch<SetStateAction<ServiceUserGuide[]>>;
}

const columns = [
  {
    Header: "id",
    accessor: "id",

    width: 88,
  },
  {
    Header: "유형",
    accessor: "type",
    Cell: ({ row }: CellProps<ServiceUserGuide>) => (
      <div className="flex-center-center">
        <span>{UserGuideTypeMap[row?.original?.userGuide?.type as UserGuideType] || "-"}</span>
      </div>
    ),

    width: 200,
  },
  {
    Header: "항목명",
    accessor: "subject",
    width: 530,
    Cell: ({ row }: CellProps<ServiceUserGuide>) => {
      const detailPath = `${PagePath.guide.detail.replace(":id", `${row?.original?.userGuide?.id}`)}`;
      return <BaseNewTabLink path={detailPath} value={row?.original?.userGuide?.subject || ""} tooltip></BaseNewTabLink>;
    },
  },

  {
    Header: "가중치",
    accessor: "orderNums",
    width: 100,
    Cell: ({ row, setServiceUserGuideListState }: CellProps<ServiceUserGuide>) => {
      const inputValue = row?.original?.orderNums?.toString();
      return (
        <div className="minmax80 text-center">
          <BaseInput
            type="text"
            name={"orderNums_" + row.original.id}
            onChange={(value: string) => {
              console.log("온체인지 실행 :>> ", value);
              setServiceUserGuideListState(
                produce((draftArr) => {
                  draftArr.forEach((draft) => {
                    if (draft.id === row.original.id) {
                      draft.orderNums = Number(onlyNumber(value));
                    }
                  });
                }),
              );
            }}
            value={inputValue}
          />
        </div>
      );
    },
  },
];

const SortSettingsModal = ({
  serviceUserGuideList,
  serviceId,
  serviceType,
}: {
  serviceUserGuideList: ServiceUserGuide[];
  serviceId: string;
  serviceType: UnionServiceType;
}) => {
  const { mutate: postGuideMapping } = usePostGuideMapping();
  const { openToast } = useToast();
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const [serviceUserGuideListState, setServiceUserGuideListState] = useState(serviceUserGuideList);

  return (
    <>
      <section className="base-abstract-modal__title border-bottom-gray">
        <h1>정렬 순서 설정</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <GuideBaseTable
          data={serviceUserGuideListState ?? []}
          columns={columns}
          setServiceUserGuideListState={setServiceUserGuideListState}
        ></GuideBaseTable>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />

        <BaseButton
          title={"저장"}
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnRightTitle: "확인",
              btnLeftTitle: "취소",
              title: "저장하시겠습니까?",
              onClick: () => {
                const postData = compareCUDData(serviceUserGuideList, serviceUserGuideListState).map((data) => ({
                  cmdType: data.cmdType,
                  id: data.id,
                  serviceId: data.serviceId,
                  orderNums: data.orderNums,
                }));
                console.log("postData :>> ", postData);

                postGuideMapping(postData, {
                  onSuccess: () => {
                    openToast({
                      type: "SUCCESS",
                      content: "저장되었습니다.",
                    });
                    setBaseModal({ isOpen: false });
                    setAbstractModalZ1({ isOpen: false });
                  },
                });
              },
            });
          }}
        />
      </section>
    </>
  );
};

export default SortSettingsModal;
