import { Cell } from "react-table";
import { UserGuideType, UserGuideTypeMap } from "src/api/guide/guide-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import DetailLink from "src/components/DetailLink";
import PagePath from "src/pagePath.json";

/* 
  이용안내 목록
*/
const guideListColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.guide.detail.replace(":id", `${props.value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {props.value || "-"}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "프로바이더",
    accessor: "providerName",
    width: 300,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.provider.detail.replace(":id", `${props.row.original.providerId}`)}`;

      return <BaseNewTabLink path={detailPath} value={props.value || "-"} ellipsis className="w-100 text-left" />;
    },
  },
  {
    Header: "유형",
    accessor: "type",
    Cell: (props: Cell<any>) => (
      <div className="flex-center-center">
        <span>{UserGuideTypeMap[props.value as UserGuideType]}</span>
        {/* <span>{props.value || "-"}</span> */}
      </div>
    ),
    width: 200,
  },
  {
    Header: "항목명",
    accessor: "subject",
    width: 400,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.guide.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div>
          <DetailLink to={detailPath} className="text-underline">
            <BaseTooltip contents={props?.row?.original?.subject || ""} />
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    Cell: (props: Cell<any>) => <BaseTooltip contents={props?.value || "-"} type="date" />,
    width: 150,
  },

  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    Cell: (props: Cell<any>) => <BaseTooltip contents={props?.value || "-"} isSingleLine />,
    width: 150,
  },
];

export default guideListColumns;
