import { Control, Controller, UseFormRegister } from "react-hook-form";
import { UserGuideModel } from "src/api/guide/guide-types";
import { BaseInput, BaseTextarea } from "src/components";
import FreeInternetNetwork from "./GuideWifiList";

type Props = {
  control: Control<UserGuideModel>;
  errors?: any;
  register: UseFormRegister<UserGuideModel>;
};

/* 
  공간상품 > 등록 or 수정 > 안내 > 와이파이 컴포넌트
*/

const GuideWifiListSection = ({ control, errors, register }: Props) => {
  const requiredMessage = "필수입력 항목입니다";
  // 고객용 네트워크 와이파이 목록

  return (
    <>
      <section className="contents-container__grid contents-container__1200 mb30">
        <div className="contents-container__grid-index">
          <p className="required">상세내용</p>
        </div>
        <div className="contents-container__grid-contents">
          {/* 고객용 네트워크 */}
          <div className="mb60">
            <div className="py16">
              <span className="text-gray500">네트워크</span>
            </div>
            <div className="border-gray mb10">
              <div className="pa16">
                <div className="flex-center mb10">
                  <div className="minmax140 required">
                    <span>위치</span>
                  </div>
                  <Controller
                    control={control}
                    name={`location`}
                    rules={{
                      required: {
                        value: true,
                        message: requiredMessage,
                      },
                    }}
                    render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                      return (
                        <BaseInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          placeholder="다수의 wifi 공유기를 제공할 경우 위치를 입력해주세요."
                          errorText={error && "필수입력 항목입니다."}
                        />
                      );
                    }}
                  ></Controller>
                </div>
                <FreeInternetNetwork control={control} errors={errors} register={register} />
                <div className="flex-center mb10">
                  <div className="minmax140">
                    <span>설명</span>
                  </div>
                  <Controller
                    control={control}
                    name={`description`}
                    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                      <BaseTextarea
                        maxLength={1000}
                        placeholder="wifi 사용 관련 안내 사항을 입력해주세요."
                        name={name}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GuideWifiListSection;
