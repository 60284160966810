import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";

import { deleteGuideAsync, getGuideListAsync } from "src/api/guide/guide-api";
import { UserGuideModel, UserGuideTypeMap } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { MediaFile } from "src/api/public-types";
import { BaseButton } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import MetaTag from "src/components/layout/MetaTag";
import MarkdownViewer from "src/components/MarkdownViewer";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import RegistrationInformation from "src/components/RegistrationInformation";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";

const BasicInfoDetail = () => {
  const { id } = useParams();
  const [guide, setGuide] = useState<UserGuideModel>();
  const [provider, setProvider] = useState<ProviderModel>();
  const navigate = useNavigate();

  const { setBaseModal } = useModal();
  const { openToast } = useToast();

  const { executeAsync: getProvider } = useApiOperation(getProviderList);

  const { executeAsync: getGuideList } = useApiOperation(getGuideListAsync);

  const { executeAsync: deleteGuide } = useApiOperation(deleteGuideAsync);

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync, { noLoading: true });

  // 상담관리 미디어 파일
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([]);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: { serviceId: number; serviceType: UnionServiceType }) => {
      const contentsList = [{ serviceId: data.serviceId, serviceType: data?.serviceType }];

      const response = await getMediaFileList({ contentsList });
      if (response.status >= 200 && response.status <= 299) {
        setMediaFiles(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  useEffect(() => {
    const fetchApi = async (guideId: string) => {
      const response = await getGuideList({ page: 0, size: 1, idList: guideId });
      const _guide: UserGuideModel | undefined = response?.data?.data?.content?.[0];

      if (!_guide) {
        return;
      }

      setGuide(response?.data?.data?.content?.[0]);

      getProvider({ providerId: _guide.providerId }).then((data) => setProvider(data?.data?.data?.content?.[0]));

      if (_guide.type === "USER_GUIDE_ETC") {
        fetchMediaList({ serviceId: Number(guideId), serviceType: ServiceTypes.SERVICE_USER_GUIDE });
      }
    };

    if (id) {
      fetchApi(id);
    }
  }, [id]);

  return (
    <>
      <MetaTag subTitle={guide?.subject || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap price-page">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"기본정보"} />

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>{guide?.type ? UserGuideTypeMap[guide?.type] : "-"}</span>
                </div>
              </div>
            </section>

            {/* 기본정보 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {provider?.providerId ? (
                    <BaseNewTabLink
                      path={`${PagePath.provider.detail.replace(":id", provider.providerId.toString())}`}
                      value={provider.providerName || "-"}
                      ellipsis
                      className="font14"
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </section>

            {guide?.type === "USER_GUIDE_WIFI" && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>적용대상</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{guide?.isOpened ? "일반 사용자용" : "방문자용"}</span>
                  </div>
                </div>
              </section>
            )}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>항목명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>{guide?.subject ?? "-"}</span>
                </div>
              </div>
            </section>
            {guide?.type !== "USER_GUIDE_WIFI" && (
              // 네트워크 타입이 아닐때만 노출
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>상세내용</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">{guide?.description ? <MarkdownViewer value={guide?.description}></MarkdownViewer> : "-"}</div>
                </div>
              </section>
            )}
            {/* 첨부파일 */}
            {guide?.type === "USER_GUIDE_ETC" && (
              //일반 타입에서만 노출
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>첨부파일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="user-info-container">
                    {mediaFiles.length === 0 ? <span>-</span> : <BaseFileUpload isOnlyView fileList={mediaFiles} />}
                  </div>
                </div>
              </section>
            )}

            {guide?.type === "USER_GUIDE_WIFI" && (
              <section className="contents-container__grid contents-container__1200 mb30">
                <div className="contents-container__grid-index">
                  <p>상세내용</p>
                </div>

                <div className="contents-container__grid-contents">
                  <div className="border-gray mb10">
                    <div className="pa16">
                      <div className="flex-center mb10">
                        <div className="minmax140">
                          <span>위치</span>
                        </div>
                        <div>
                          <span className="font14">{guide?.location ?? "-"}</span>
                        </div>
                      </div>
                      {/* 네트워크 */}
                      <div className="flex-start mb10">
                        <div className="minmax140">
                          <p>
                            네트워크 이름/
                            <br />
                            비밀번호
                          </p>
                        </div>
                        <table className="inner-table" width="100%">
                          <thead>
                            <tr>
                              <th>
                                <span>No</span>
                              </th>
                              <th>
                                <span>네트워크 이름</span>
                              </th>
                              <th>
                                <span>비밀번호</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {guide?.wifiList &&
                              guide.wifiList
                                .sort((a, b) => Number(a.id || 0) - Number(b.id || 0))
                                .map((wifi, index: number) => (
                                  <tr key={index.toString()}>
                                    <td width="100">
                                      <div className="flex-center-center">
                                        <span>{index + 1}</span>
                                      </div>
                                    </td>
                                    <td width="220">
                                      <div className="flex-center-center">
                                        <span className="font14">{wifi.wifiName ?? "-"}</span>
                                      </div>
                                    </td>
                                    <td width="auto">
                                      <div className="flex-center-center">
                                        <span className="font14">{wifi.wifiPassword ?? "-"}</span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex-center mb10">
                        <div className="minmax140">
                          <span>설명</span>
                        </div>
                        <div>
                          <span className="font14 pre-formatted">{guide?.description ?? "-"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </article>

          <RegistrationInformation data={guide} />

          <AdminMemoV3 serviceId={Number(id)} serviceType={ServiceTypes.SERVICE_USER_GUIDE} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="left-area d-flex">
          <BaseButton
            title="삭제"
            className="color-white size-large mr10"
            onClick={() => {
              setBaseModal({
                isOpen: true,
                title: "삭제하시겠습니까?",
                btnLeftTitle: "취소",
                btnRightTitle: "확인",
                onClick: () => {
                  deleteGuide({ idList: id! }).then((data) => {
                    if (data.data.data.content) {
                      openToast({ content: "정상적으로 삭제 되었습니다." });
                      navigate(PagePath.guide.list);
                    } else {
                      openToast({ content: "정상적으로 삭제 되지 않았습니다.", type: "FAIL" });
                    }
                  });

                  setBaseModal({ isOpen: false });
                },
              });
            }}
          />
          <BaseButton
            title="수정"
            className="size-large"
            onClick={() => {
              const formPath = `${PagePath.guide.form}?id=${id}`;
              navigate(formPath);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
