import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getProviderList } from "src/api/provider/provider-api";
import { BaseInput, BaseRadio } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import Link from "src/components/PartnerLink";
import TableSelectModal from "src/components/TableSelectModal";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat } from "src/utils";

// feature 1 :: 선택 저장 후 부모에 보낼 타입지정
// feature 2 :: TABLE_COLUMNS변경. TABLE_COLUMNS 내 id 명칭 체크 (선택에 영향있음 ex) providerId )
// feature 3 :: checked 영역 확인 , keyword 명칭, 제목 확인
// feature 4 :: fetchApi 변경

type SelectedType = {
  // 변경
  id?: number;
  providerId: string;
  providerName: string;
};

interface Props {
  onCanceled: () => void;
  onAdded: (selected: SelectedType[]) => void;
  defaultChecked: SelectedType[];
}

interface CellProps extends Cell<SelectedType> {
  checked: boolean;
}

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const id = props.row.original.providerId;
      let disabled = false;
      return (
        <div data-data-id={id} data-checked={props.value} className="checkbox" data-disabled={disabled}>
          <BaseRadio id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "providerId",
    width: 100,
    Cell: (props: CellProps) => {
      const providerId = props.row.original.providerId;
      const detailPath = `${PagePath.provider.detail.replace(":id", `${providerId}`)}`;
      return (
        <div>
          <BaseNewTabLink path={detailPath} value={props.value || "-"} ellipsis />
        </div>
      );
    },
  },
  {
    Header: "프로바이더명",
    accessor: "providerName",
    width: 340,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return <div className="w-100 text-left">{props.value}</div>;
    },
  },
  {
    Header: "사업자 등록 번호",
    accessor: "businessRegistrationNumber",
    width: 210,
    Cell: (props: CellProps) => <p>{props.value ? props.value : "-"}</p>,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 210,
    Cell: (props: CellProps) => <p>{props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</p>,
  },
];

function ProviderSelectModal({ onCanceled, onAdded, defaultChecked }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [tableDataList, setTableDataList] = useState<any[]>([]);
  const [selectedList, setSelectedList] = useState<SelectedType[]>(defaultChecked);
  const [size, setSize] = useState(20);

  const { executeAsync: getProvider } = useApiOperation(getProviderList);

  const { handleSubmit, getValues, control } = useForm<{ searchValue: string }>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const listTableData = tableDataList.map((item) => {
    const selectedIdList = selectedList?.map((item) => item.providerId);

    return {
      checked: selectedIdList.includes(item.providerId),
      ...item,
    };
  });

  const search = ({ searchValue, page, size }: { page: number; size: number; searchValue?: string }) => {
    setSize(size);
    fetchApi({ page, size, searchValue });
  };

  const onSubmit = ({ searchValue }: { searchValue: string }) => search({ page: 0, size, searchValue });
  const goPage = (nextPage: number) => search({ page: nextPage, size, searchValue: getValues("searchValue") });

  const fetchApi = async ({ searchValue, page, size }: { page: number; size?: number; searchValue?: string }) => {
    const result = await getProvider({
      supplyType: "RENTAL",
      page,
      searchValue,
      size,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setTableDataList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const select = (id: string) => {
    const selectedProvider = tableDataList.find((item) => String(item.providerId) === id);

    setSelectedList([
      // ...selectedList,
      // 멀티셀렉일때 주석해제
      {
        providerId: selectedProvider?.providerId || "",
        providerName: selectedProvider?.providerName || "",
      },
    ]);
  };
  const unSelect = (id: string) => {
    const filteredList = selectedList.filter((object) => String(object.id) !== id);
    setSelectedList(filteredList);
  };
  const _onAdded = () => onAdded(selectedList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchApi({
      page: 0,
      size,
      searchValue: "",
    });
  }, []);

  return (
    <TableSelectModal<any>
      className=""
      onCanceled={onCanceled}
      onAdded={_onAdded}
      goPage={goPage}
      page={page}
      columns={TABLE_COLUMNS}
      items={listTableData}
      title={"프로바이더"}
      select={select}
      unSelect={unSelect}
      sizeOption={(size) => {
        setSize(size);
        search({ page: 0, size, searchValue: getValues("searchValue") });
      }}
      currentSize={size}
      titleFormRender={() => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
            <Controller
              name="searchValue"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="프로바이더 명 또는 사업자"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                  onKeyUp={handleSearchClick}
                  clearable
                />
              )}
            />
          </form>
        );
      }}
    />
  );
}
export default ProviderSelectModal;
