export const DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"] as const;

export const guideTypeOptions = [
  { value: "", label: "전체" },
  {
    value: "USER_GUIDE_ETC",
    label: "일반",
  },
  {
    value: "USER_GUIDE_PARKINGLOT",
    label: "주차",
  },
  {
    value: "USER_GUIDE_WIFI",
    label: "네트워크",
  },
];
