import { memo, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { menuTitleState } from "src/recoil/menuTitle/atom";
import useModalObserver from "src/recoil/modal-observer/hooks";
import { isPublicPage } from "src/utils/common-util";
import { GlobalNav } from "./GlobalNav";
import Layout from "./Layout";
import MetaTag from "./MetaTag";
import { SideBar } from "./SideBar";

/**
 * App > Container
 */

export const menuTitle = [
  {
    title: "대시보드",
    keyword: "main",
  },
  {
    title: "건물",
    keyword: "building",
  },
  {
    title: "공간 모니터링",
    keyword: "monitoring",
  },
  {
    title: "워크오더",
    keyword: "workorder",
  },
  {
    title: "CS",
    keyword: "question",
  },
  {
    title: "상품",
    keyword: "product",
  },
  {
    title: "신청/계약",
    keyword: "contract",
  },
  {
    title: "정산 정보",
    keyword: "provider",
  },
  {
    title: "공용공간 예약",
    keyword: "reservation",
  },
  {
    title: "이용자 초대",
    keyword: "user",
  },
  {
    title: "방문자 초대",
    keyword: "visitor",
  },
  {
    title: "사업자",
    keyword: "org",
  },
  {
    title: "빌링 스케줄",
    keyword: "billing",
  },
  {
    title: "공용공간 요금",
    keyword: "price",
  },
  {
    title: "공용공간 휴무일시",
    keyword: "closedPeriod",
  },
  {
    title: "VoC 항목",
    keyword: "voc",
  },
  {
    title: "관리비 부과항목관리",
    keyword: "chargeItems",
  },
  {
    title: "관리비 부과관리",
    keyword: "chargeMnt",
  },
  {
    title: "공지사항",
    keyword: "notice",
  },
  {
    title: "이용안내",
    keyword: "guide",
  },
];

function Container() {
  const location = useLocation();
  const { pathname } = location;
  const setMenuTitle = useSetRecoilState(menuTitleState);
  const nowMenuURL = location.pathname.split("/")[5];
  const isInitPage = location.pathname.includes("init");
  const isErrorPage = location.pathname.includes("error");
  const isAccessDeniedPage = location.pathname.includes("accessdenied");

  const { notify, getLastId, modalObserver } = useModalObserver();

  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        const modalId = getLastId();
        if (modalId) {
          e.preventDefault();
          notify(modalId, e.key);
        }
      }
    };

    window.addEventListener("keyup", escapeKeyHandler);

    return () => {
      window.removeEventListener("keyup", escapeKeyHandler);
    };
  }, [modalObserver]);

  useEffect(() => {
    if (nowMenuURL) {
      const findNowMenu = menuTitle.find((el) => el.keyword === nowMenuURL);
      findNowMenu ? setMenuTitle(findNowMenu.title) : setMenuTitle("");
    } else {
      setMenuTitle("");
    }
  }, [isAccessDeniedPage, isInitPage, nowMenuURL, setMenuTitle]);

  return (
    <HelmetProvider>
      <>
        <MetaTag />
        <div className={!isPublicPage(pathname) && isErrorPage ? "base-pc-fix base-pc-error" : !isPublicPage(pathname) ? "base-pc-fix" : ""}>
          {isPublicPage(pathname) === false && ( //
            <GlobalNav
              logo={isInitPage ? isInitPage : isErrorPage ? isErrorPage : isAccessDeniedPage}
              className={isInitPage || isAccessDeniedPage || isErrorPage ? "no-sidebar-page" : ""}
            />
          )}
          {isPublicPage(pathname) === false && //
            !pathname.includes("/mng/ctrl/init") &&
            !pathname.includes("/error/page-not-found") &&
            !pathname.includes("/mng/ctrl/accessdenied") && <SideBar />}
          <Layout />
        </div>
      </>
    </HelmetProvider>
  );
}

export default memo(Container);
